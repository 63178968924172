<template>
  <div class="set-height kuang" :class="{'fu-dong':fixed}">
    <div class="left" @click="$router.back(-1)">
      <i class="left-icon iconfont  icon-arrow-left-bold"></i>
    </div>
    <div class="content">
      <router-link :to="{name:'Index'}">
        <van-image :src="login" height="35" width="100"/>
      </router-link>
    </div>
    <div class="right">
      <a href="" @click.prevent="show = true">
        <i class="iconfont icon-daohang"></i>&nbsp;导航
      </a>
    </div>
  </div>
  <div class="set-height" v-if="fixed"></div>
  <van-popup
      v-model:show="show"
      position="right"
      :style="{ height: '100%',width:'200px' }"
  >
    <div class="tal-h-full tal-flex tal-flex-col">
      <div class="tal-flex-1 tal-overflow-auto">
        <van-cell
            v-for="item in actions"
            :title="item.name"
            :to="{name:item.pageName}"
            is-link
        />
      </div>
      <div>
        <van-button block @click="show= false" type="primary">关闭</van-button>
      </div>
    </div>

  </van-popup>

</template>

<script>
import {ref} from 'vue'
import {useRouter} from 'vue-router'
import {useWindowSize} from '@vant/use'
import loginImage from "@/assets/icon/login.png"

export default {
  name: 'homeHeader',
  props: {
    fixed: Boolean
  },
  activated() {
    this.show = false
  },
  setup() {
    const {width, height} = useWindowSize();

    let show = ref(false)
    const router = useRouter()
    const actions = [
      {name: '服务大厅', pageName: 'FuWuDaTing'},
      {name: '项目大厅', pageName: 'XiangMuDaTing'},
      {name: '案例库', pageName: 'AnLiDaTing'},
      {name: '企业培育库', pageName: 'ShouJiInfo'},
      {name: '服务商入驻', pageName: 'fuWuShangRuZhu'},
      {name: '工程师入驻', pageName: 'gcs_index'},
      {name: '渠道合作', pageName: 'QuDaoHeZuo'},
      {name: '个人中心', pageName: 'UserHome'},
      {name: '专家库', pageName: 'PlatformSpecialistLibrary'},
      {name: '公司新闻', pageName: 'PlatformCompanyNews'},
      // {name: '政策资讯', pageName: 'zheng_fu_zi_xun'},
      {name: '常见问题', pageName: 'PlatformGeneralProblem'},
      {name: '关于云佐', pageName: 'PlatformPlatformProduce'},
      {name: '惠企政策', pageName: 'PolicyIndex'},
      {name: '投资项目审批及建设', pageName: 'approvalProcess'},
    ];

    function onSelect(item) {
      router.push({name: item.pageName})
    }

    return {
      height,
      actions,
      login: loginImage,
      show,
      onSelect
    }
  }


}

</script>

<style scoped lang="less">
@import "../../assets/style";

.kuang {
  display: flex;
  padding: 0px 5px;
  background-color: #fff;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  padding: 6px 5px 6px;
  background-color: @bai-se;

  .left {
    position: relative;
    width: 30px;
    text-align: center;
    color: #4A4096;
    font-weight: 700;

    .left-icon {
      margin-top: 2px;
      //font-size: @fan-hui-icon;
    }
  }

  .content {
    display: flex;
    align-items: center;
    line-height: 0;
  }

  .right {

  }
}

.left-icon {
  font-size: 18px;
}

.fu-dong {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 10;
}

.set-height {
  height: 47px;
}
</style>
