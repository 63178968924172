<template>
  <home-header/>
  <div style="height: 100%;background-color: #fff;overflow: auto">
    <div v-html="info.top">
    </div>
    <div class="form-bf">
      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <van-field
              v-model="form.message"
              label="联系人"
              placeholder="请输入联系人名称"
          />
          <van-field
              v-model="form.mobile"
              label="手机号"
              placeholder="请输入手机号"
          />
        </van-cell-group>
        <div style="margin: 16px;">
          <van-button block type="primary" native-type="submit">
            立即提交
          </van-button>
        </div>
      </van-form>
    </div>
    <div v-html="info.footer">

    </div>
  </div>
</template>

<script>
import HomeHeader from "@/views/open/homeHeader";

export default {
  name: 'QuDaoHeZuo',
  components: {HomeHeader},
}
</script>
<script setup>
import http from "@/api/http";
import {ref} from 'vue'
import _ from 'lodash'
import din_shi from "@/api/din_shi";

let info = ref({
  top: '',
  footer: ''
})
http.get('/portal/index/get_qu_dao_he_zuo_info').then(da => {
  if (da.code === 1) {
    info.value.top = _.get(da, 'data.content')
    info.value.footer = _.get(da, 'data.else_content')
  }
})
let form = ref({})

function onSubmit() {
  http.post('/gzhphp/fu_wu_shang/addQuDaoHeZuo', form.value).then(da => {
    if (da.code === 1) {
      din_shi.success(da.msg)
      form.value.message = ''
      form.value.mobile = ''
    }
  })
}
</script>

<style scoped>
::v-deep body {
  background-color: #fff;
}

.form-bf {
  /*padding: 20px;*/
  margin: 20px;
  /*background-color: #ffd636;*/
}
</style>
